.container{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
}

.container h1{
  display: inline-block;
  padding: 0 20px;
  text-align: center;
  text-transform: uppercase;
  border-top: 2px solid;
  border-bottom: 2px solid;
  margin-top: 50px;
  margin-bottom: 50px;
}

.blockContainer{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 250px;
}

.blockTextContainer{
  flex: 50%;
  padding: 30px;
  box-sizing: border-box;
  text-align: justify;
  padding: 10px 50px;
}

.blockTextContainer h2{
  text-align: center;
}

.blockTextContainer ul{
  padding-left: 10px;
}
.blockTextContainer ol{
  padding-top: 10px;
  padding-bottom: 10px;
}
.blockTextContainer li{
  margin-bottom: 10px;
}
.blockTextContainer a{
  padding: 0 5px;
}
.highlight{
  background: #fff982;
  padding: 5px 15px;
  display: inline-block;
}

.container img{
  height: 70px;
    margin: 0 auto;
    position: relative;
    display: block;
    opacity: .4;

}

.borderedList{
  border: 1px solid;
  padding: 10px 40px !important;
  border-radius: 3px;
}

.linkto{
  text-transform: uppercase;
  color: black;
  font-weight: bold;
  display: flex;
  justify-content: center;
  margin-top: 120px;
}

@media (max-width: 768px) {
    .blockTextContainer{
      flex: 100%;
      padding: 10px;
  }
}