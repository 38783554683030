.footer{
  bottom: 0;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  width: 100vw;
  background-color: black;
  color: white;
  left: 0;
  position: absolute;
}
