h3{
  font-size: 20px;
}
.logo_container{
  max-width: 900px;
  justify-items: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 25px 0;
}

.logo_container img{
  max-height: 100px;
  max-height: 50px;
  margin: 20px;
  max-width: 300px;
  margin: 30px 40px;
}

.logo_container img:first-child{
  padding-left:10px;
}
.first_chunk{
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}
.second_chunk{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.sidnLogo{
  max-height: 40px !important;
}

.europeanLogo{
  max-height: 100px !important;
}

.logo_in_text{
  border: 1px solid;
  border-radius: 3px;
  padding: 5px 10px;
  margin-top: 20px;
}

@media (max-width: 768px) {
  h3{
  text-transform: uppercase;
  }
  .logo_container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 40px;
    padding: 0;
  }
  .logo_container img{
    max-height: 50px;
    margin: 15px 0;
  }
  .logo_container img:first-child{
    padding-left: 0;
  }
  .first_chunk{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .second_chunk{
    justify-content: flex-start;
  }
}