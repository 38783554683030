body {
  margin: 0;
  font-family: 'ArchivoVariable', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 1px;
  line-height: 1.6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
 font-family: "ArchivoVariable";
 src: local("ArchivoVariable"),
  url("./fonts/Archivo/Archivo-Variable.ttf") format("truetype");
}
@font-face {
 font-family: "ArchivoItalic";
 src: local("ArchivoItalic"),
  url("./fonts/Archivo/Archivo-Italic.ttf") format("truetype");
}
