.home_container{
  max-width: 900px;
  margin: 10px auto 150px;
}

.text_container {
  background-color: white;
  padding: 20px;
  border-radius: 3px;
  color: black;
}

.text_container a{
  color: black;
  margin: 1em;
  display: block;
}

.alert_container{
  font-size: 14px;
  text-transform: uppercase;
  background: #fff982;
  padding: 5px 15px;
  display: inline-block;
  font-weight: bold;
}

@media (max-width: 768px) {
    .home_container{
    max-width: unset;
    margin: 0;
    margin-bottom: 120px;
  }
  .text_container {
    padding: 0;
  }
}