.navbar{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  margin-bottom: 50px;
}


.navbar img{
  max-height: 55px;
}

.link{
  height: 100%;
  width: 100%;
  display: block;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navigate{
  color: black;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 14px;
  position: absolute;
  right: 40px;
}

.navigate:hover{
  text-decoration: underline;
}

@media (max-width: 768px) {
  .navbar{
    margin-bottom: 20px;
  }
  .navigate{
    display: none;
  }
}