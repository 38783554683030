.only_mobile{
  display: none;
}

.link{
  color: black;
  margin: 50px 20px 20px;
  display: block;
  text-align: center;
}

hr{
  margin: 40px 0;
}

.inputhidden {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.row {
  display: flex;
}
.row .col {
  flex: 1;
}
.row .col:last-child {
  margin-left: 1em;
}

/* Accordion styles */
.tabs {
  overflow: hidden;
}

.tab {
  width: 100%;
  overflow: hidden;
}
.tab_label {
  display: flex;
  justify-content: space-between;
  padding: 1em 0;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
  /* Icon */
}

.tab_label::after {
  content: "❯";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all 0.35s;
}
.tab_content {
  max-height: 0;
  transition: all 0.35s;
}
.tab_close {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  cursor: pointer;
}


input:checked + .tab_label::after {
  transform: rotate(90deg);
}
input:checked ~ .tab_content {
  max-height: 100vh;
  margin-bottom: 20px;
}


@media (max-width: 768px) {

  .only_desktop{
    display: none;
  }
  .only_mobile{
    display: block;
  }
  .ulist{
    margin: 0;
    margin-top: 10px;
  }
}