.about_container{
  text-align: left;
  max-width: 900px;
  margin: 0 auto 150px;
}

.only_mobile{
  display: none;
}

.link{
  color: black;
  margin: 50px 20px 20px;
  display: block;
  text-align: center;
}
.only_desktop h3{

  font-size: 20px;
}
ul.tab_content { display: none;}

@media (max-width: 768px) {
  .about_container{
    max-width: 100%;
  }
  .only_desktop{
    display: none;
  }
  .only_mobile{
    display: block;
  }
}