.container {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}

.h2{
  color: #d60000;
  margin: 0;
  font-size: 50px;
  margin-bottom: -20px;
}

@media (max-width: 768px) {
  .h1{
    font-size: 24px;
  }
}