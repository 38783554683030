.container{
  width: 600px;
  margin: 0 auto;
  padding: 40px 40px 20px;
  margin-top: 40px;
}
.formContainer{
  width: 100%;
  display: flex;
}
.formContainer input{
  width: 450px;
  border: 2px solid black;
  border-radius: 3px;
  height: 40px;
  margin: 0 auto;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 100%;
}
.formContainer .button{
  border: 2px solid black;
  border-radius: 3px;
  height: 46px;
  background-color: black;
  color: white;
  text-transform: uppercase;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 0 20px;
  cursor: pointer;
  width: 100%;
  margin: 0;
}
.formContainer form{
  width: 100%;
}
.formContainer label{
  font-size: 13px;
  opacity: .7;
  width: 100%;
  text-align: left;
  display: block;
  margin-bottom: 5px;
}

.formContainer input::-webkit-input-placeholder {
  color: transparent;
}

.subscribeMessage{
  font-size: 23px;
  display: block;
  margin-bottom: 20px;
}

.formContainer > div{
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}

.mcFieldGroup{
  width: 80%;
}
.buttonContainer{
  width: 20%;
}

@media (max-width: 768px) {
  .container{
    width: unset;
    margin-top: 50px;
    padding: 0;
    border-top: 1px solid;
    padding-top: 40px;
    margin-top: 60px;
  }
}