.container{
  display: flex;
  align-items: center;
  justify-content: center;
}

.container img{
  max-height: 30px;
  margin: 10px
}

@media (max-width: 768px) {
  .container{
    margin-top: 20px;
  }
}